<template>
  <div class="main gray-bg">
    <van-nav-bar class="top-bar has-bg-bar" title="礼包" left-arrow @click-left="onClickLeft"/>
    <van-list class="gift-list" v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getGiftList">
      <van-cell v-for="(item, key) in list" :key="key" class="gift-cell" :class="item.received ? 'receiving' : 'received'" clickable :to="'/personal/gift-detail/'+item.id">
        <template #title>
          <div class="van-ellipsis gift-title">{{ item.giftbag_name }}</div>
          <div class="van-ellipsis gift-desc">{{item.digest }}</div>
        </template>
        <template #right-icon>
          <van-button v-if="item.received" class="gift-button">查看</van-button>
          <van-button v-else class="gift-button" @click.stop="onReceive(item,key)">领取</van-button>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import {NavBar,Cell, List,Button,Toast} from "vant";
import "@/style/common.css"
import {reactive, ref} from "vue";
import {giftList, receiveGift} from "@/api/game";
import {exitPage} from "@/utils/callback";
import {useStore} from "vuex";
export default {
  name: "GiftList",
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
    [Button.name]: Button,
  },
  setup() {
    const store = useStore();
    const list = ref([])
    const query = reactive({
      last:0,
      limit:10
    })
    const loading = ref(false);
    const finished = ref(false);
    const onClickLeft = ()=>{
      exitPage()
    };
    const getGiftList = () => {
      loading.value = true;
      let oldList = list.value;
      giftList(query).then(({data})=>{
        loading.value = false;
        list.value = [...oldList,...data.list];
        query.last = data.last
        if(data.list.length < query.limit || data.last == 0){
          finished.value = true;
        }
      })
    }
    const onReceive = (data,index)=>{
      receiveGift(data.id,store.state.smallId).then(()=>{
        Toast('领取成功')
        list.value[index].received = 1
      })
    }
    return {
      list,
      loading,
      finished,
      onClickLeft,
      getGiftList,
      onReceive,
    }
  }
}
</script>

<style scoped>
.top-bar :deep(.van-nav-bar__content){
  background: url("../../../assets/images/personal/gift-bar.png") bottom center no-repeat;
  background-size: 100% auto;
}
.gift-list{
  padding:6px 16px;
}
.gift-cell{
  margin:6px auto;
  padding:10px 16px;
  border-radius: 4px;
  height: 68px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
}
.gift-cell :deep(.van-cell__title){
  text-align: left;
}
.gift-title{
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
.gift-desc{
  font-size: 12px;
  font-weight: 400;
  color: #86909C;
  line-height: 12px;
  margin-top:10px;
}
.gift-button{
  width: 62px;
  height: 32px;
  background: #FF7056;
  border-radius: 16px;
  border:none;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding:0px;
}
</style>
